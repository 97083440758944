import '../styles.scss';
import NavbarWithBackLink from 'components/molecules/NavbarWithBackLink/NavbarWithBackLink';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import TestIntro from 'components/organisms/TestIntro/TestIntro';
import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import { TestStatus } from 'generated/graphql';
import { ISkillAssessmentIntroPath } from 'interfaces/routes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import config from 'views/Test/TestInfo/TestInfo.constants';

const DeiIntro = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { stageCandidateId } = useParams() as ISkillAssessmentIntroPath;
  useEffect(() => {
    if (!stageCandidateId) {
      navigate(appSwitchRoutes.stages.getUrl());
    }
  }, [navigate, stageCandidateId]);

  const startAssessment = () => {
    if (stageCandidateId) {
      navigate(stageSwitchRoutes.deiForm.getUrl(stageCandidateId));
    }
  };

  const singleTestConfig = config['dei']();
  const MainIcon = singleTestConfig.icon;

  return (
    <>
      <HelmetAndPageAnnouncer
        pageTitle={t('test.instructions.skillsSelfAssessment.name')}
      />
      <NavbarWithBackLink
        to={appSwitchRoutes.stage.getUrl(stageCandidateId || 0)}
        ariaLabel={t('assessment.returnToAllAssessmentActions')}
        label={t('assessment.actions')}
      />
      <TestIntro
        MainIcon={MainIcon}
        title={singleTestConfig.title}
        notes={singleTestConfig.notes}
        testStatus={TestStatus.NotStarted}
        hasPractice={false}
        onStart={startAssessment}
        onStartPracticeTest={() => {}}
        testTimePerQuestion={2}
      />
    </>
  );
};

export default DeiIntro;
