import Icon, { IconType } from 'components/atoms/Icon';
import { StageActionAvailabilityStatus } from 'generated/graphql';
import { FC, MouseEventHandler } from 'react';
import { ActionCard } from '@spotted-zebra-uk/ui-components';

interface IStageActionCard {
  status: StageActionAvailabilityStatus;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  title: string;
  icon: IconType;
  dataTestType?: string;
  linkTo: string;
  ariaLabel?: string;
}

const StageActionCard: FC<IStageActionCard> = ({
  status,
  onClick,
  title,
  icon,
  linkTo,
  ariaLabel,
  dataTestType,
}) => (
  //@ts-ignore
  <ActionCard
    to={linkTo}
    disabled={StageActionAvailabilityStatus.NotAvailable === status}
    onClick={onClick}
    label={title}
    completed={StageActionAvailabilityStatus.Completed === status}
    ariaLabel={ariaLabel}
    data-test-type={dataTestType}
    data-test-id="stage-action-card"
    className="stage-action-card"
    icon={
      <Icon
        className="stage-action-card__icon"
        icon={icon}
        aria-hidden
        focusable="false"
      />
    }
  />
);

export default StageActionCard;
