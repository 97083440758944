import { stageSwitchRoutes } from 'constants/routes';
import {
  RssAssessmentCandidateStatus,
  RssAssessmentType,
  StageActionAvailabilityStatus,
  StageActionFragment,
} from 'generated/graphql';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StageActionCard from '../StageActionCard';

interface IStageActionSkillsSelfAssessmentCard {
  title: string;
  actionCard: StageActionFragment;
  stageCandidateId: number;
  softRssAssessmentCandidateId?: number;
  softRssAssessmentCandidateStatus?: RssAssessmentCandidateStatus;
  technicalRssAssessmentCandidateId?: number;
  technicalRssAssessmentCandidateStatus?: RssAssessmentCandidateStatus;
}

const StageActionSkillsSelfAssessmentCard: FC<
  IStageActionSkillsSelfAssessmentCard
> = ({
  title,
  actionCard,
  stageCandidateId,
  softRssAssessmentCandidateId,
  softRssAssessmentCandidateStatus,
  technicalRssAssessmentCandidateId,
  technicalRssAssessmentCandidateStatus,
}) => {
  const { t } = useTranslation();
  const isCardAvailable =
    actionCard.availability.status === StageActionAvailabilityStatus.Available;
  const navigate = useNavigate();
  const skillsSelfAssessmentIntroURL =
    stageSwitchRoutes.skillsSelfAssessmentIntro.getUrl(
      stageCandidateId,
      softRssAssessmentCandidateId || 0,
      RssAssessmentType.SoftSkill
    );

  const handleCardClick: MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault();
    if (!isCardAvailable) {
      return;
    }

    if (
      softRssAssessmentCandidateId &&
      softRssAssessmentCandidateStatus !==
        RssAssessmentCandidateStatus.Submitted
    ) {
      return navigate(skillsSelfAssessmentIntroURL);
    }

    if (
      technicalRssAssessmentCandidateId &&
      technicalRssAssessmentCandidateStatus !==
        RssAssessmentCandidateStatus.Submitted
    ) {
      return navigate(
        stageSwitchRoutes.skillsSelfAssessmentIntro.getUrl(
          stageCandidateId,
          technicalRssAssessmentCandidateId,
          RssAssessmentType.TechnicalSkill
        )
      );
    }
  };

  const ariaLabel =
    StageActionAvailabilityStatus.Completed === actionCard.availability.status
      ? t('assessment.skillsSelfAssessmentCompleted')
      : t('assessment.skillsSelfAssessment');

  return (
    <StageActionCard
      status={actionCard.availability.status}
      onClick={handleCardClick}
      title={title}
      icon="skills_self_assessment"
      linkTo={skillsSelfAssessmentIntroURL}
      ariaLabel={ariaLabel}
    />
  );
};

export default StageActionSkillsSelfAssessmentCard;
