import Icon from 'components/atoms/Icon';
import Loader from 'components/atoms/Loader/Loader';
import { HelmetAndPageAnnouncer } from 'components/organisms/HelmetAndPageAnnouncer/HelmetAndPageAnnouncer';
import StageNavbar from 'components/organisms/StageNavbar';
import { appSwitchRoutes } from 'constants/routes';
import {
  StageActionAvailabilityStatus,
  StageActionDeiFormModel,
  StageActionFragment,
  StageCandidateStatus,
  StageStatus,
  useDeiStageActionCandidateSubmitResponsesMutation,
  useGetStageActionsQuery,
} from 'generated/graphql';
import { IStageActionFormPath } from 'interfaces/routes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import DeiFormFields from './DeiFormFields';
import { getDeiFieldsWithOptions } from './StageActionDeiForm.helpers';
import styles from './StageActionDeiForm.module.scss';

const StageActionDeiForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stagesUrl = appSwitchRoutes.stages.getUrl();
  const { handleMsgType } = useNotification();
  const { stageCandidateId } = useParams() as IStageActionFormPath;

  const { data: stageData, loading } = useGetStageActionsQuery({
    variables: { stageCandidateId: Number(stageCandidateId) },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });

      navigate(stagesUrl);
    },
    onCompleted: data => {
      const stageStatus = data.GetStageActions.stage.status;
      const stageCandidateStatus = data.GetStageActions.stageCandidate.status;
      const deiStatus = data.GetStageActions.actions.find(
        action => action.__typename === 'StageActionDeiFormModel'
      )?.availability.status;

      if (
        stageStatus === StageStatus.Expired ||
        stageStatus === StageStatus.Scheduled ||
        deiStatus === StageActionAvailabilityStatus.Completed ||
        stageCandidateStatus === StageCandidateStatus.Completed ||
        stageCandidateStatus === StageCandidateStatus.SignedOff ||
        stageCandidateStatus === StageCandidateStatus.ManagerCompleted
      ) {
        navigate(stagesUrl);
      }
    },
    fetchPolicy: 'network-only',
  });

  const [submitDeiResponses, { loading: loadingSubmit }] =
    useDeiStageActionCandidateSubmitResponsesMutation({
      onCompleted: () => {
        navigate(appSwitchRoutes.tpCallback.getUrl(stageCandidateId));
      },
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        });
      },
    });

  const formFields = useMemo(() => {
    const deiAction = stageData?.GetStageActions?.actions.find(
      (action: StageActionFragment) =>
        action.__typename === 'StageActionDeiFormModel'
    ) as StageActionDeiFormModel;
    return getDeiFieldsWithOptions(
      deiAction?.deiStageActionCandidate?.stageAction?.fields || []
    );
  }, [stageData]);

  const handleSubmitDeiForm = (payload: { [key: string]: string }) => {
    submitDeiResponses({
      variables: {
        responses: payload,
        stageCandidateId: Number(stageCandidateId),
      },
    });
  };

  if (loading) {
    return (
      <div className="rs-form__LoaderWrapper">
        <Loader />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <HelmetAndPageAnnouncer pageTitle={t('test.instructions.dei.name')} />
      <StageNavbar isForm />
      <div className={styles.header}>
        <Icon icon="dei" aria-hidden />
        <div>{t('test.instructions.dei.name')}</div>
      </div>
      <div className={styles.formWrapper} data-testid="stage-action__dei-form">
        <DeiFormFields
          formFields={formFields}
          loadingSubmit={loadingSubmit}
          onSubmit={handleSubmitDeiForm}
        />
      </div>
    </div>
  );
};

export default StageActionDeiForm;
