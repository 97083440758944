import { appSwitchRoutes, stageSwitchRoutes } from 'constants/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Authentication } from 'views/Authentication/Authentication';
import CalibrateForm from 'views/CalibrationForm/CalibrationForm';
import CodingAssessmentThankYou from 'views/CodingAssessmentThankYou/CodingAssessmentThankYou';
import CompanyNavigation from 'views/CompanyNavigation/CompanyNavigation';
import ExternalServiceLanding from 'views/ExternalServiceLanding/ExternalServiceLanding';
import { IndirectInvitation } from 'views/IndirectInvitation/IndirectInvitation';
import PersonalSkillsProfile from 'views/PersonalSkillsProfile';
import RoleSpecificSkillsAssessment from 'views/RoleSpecificSkillsAssessment';
import SkillsAssessmentThankYou from 'views/SkillsSelfAssessmentThankYou';
import CodingIntro from 'views/Stage/StageIntros/CodingIntro';
import DeiIntro from 'views/Stage/StageIntros/DeiIntro/DeiIntro';
import SkillsAssessmentIntro from 'views/Stage/StageIntros/SkillsSelfAssessmentIntro';
import StageView from 'views/Stage/StageView';
import TestCallback from 'views/Stage/TestCallback/TestCallback';
import StageActionDeiForm from 'views/StageActionDeiForm';
import StageActionForm from 'views/StageActionForm';
import Stages from 'views/Stages/Stages';
import TalentReviewForm from 'views/TalentReviewForm/TalentReviewForm';
import TalentReviewInfo from 'views/TalentReviewInfo/TalentReviewInfo';
import Test from 'views/Test/Test';
import { CandidateInformationPageWrapper } from '../../views/CandidateInformation/CandidateInformationModal/CandidateInformationPageWrapper/CandidateInformationPageWrapper';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const RootRedirection = () => <Navigate to={appSwitchRoutes.stages.path} />;

function AppSwitch() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute
            redirectUrl={appSwitchRoutes.authentication.getUrl() + '/sign-up'}
          />
        }
      >
        <Route
          path={appSwitchRoutes.indirectInvitation.path}
          element={<IndirectInvitation />}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path={appSwitchRoutes.stages.path} element={<Stages />} />

        <Route
          path={appSwitchRoutes.personalSkillsProfile.path}
          element={<PersonalSkillsProfile />}
        />
        <Route
          path={stageSwitchRoutes.tpCallback.path}
          element={<TestCallback />}
        />
        <Route
          path={stageSwitchRoutes.practiceTestCallback.path}
          element={<TestCallback />}
        />
        <Route
          path={stageSwitchRoutes.codingAssessmentThankYou.path}
          element={<CodingAssessmentThankYou />}
        />
        <Route path={stageSwitchRoutes.stage.path} element={<StageView />} />
        <Route
          path={stageSwitchRoutes.trform.path}
          element={<TalentReviewForm />}
        />
        <Route
          path={stageSwitchRoutes.calibrate.path}
          element={<CalibrateForm />}
        />
        <Route path={stageSwitchRoutes.test.path} element={<Test />} />

        <Route
          path={stageSwitchRoutes.candidateInformation.path}
          element={<CandidateInformationPageWrapper />}
        />
        <Route
          path={stageSwitchRoutes.stageActionForm.path}
          element={<StageActionForm />}
        />
        <Route
          path={stageSwitchRoutes.skillsSelfAssessmentIntro.path}
          element={<SkillsAssessmentIntro />}
        />
        <Route path={stageSwitchRoutes.deiIntro.path} element={<DeiIntro />} />
        <Route
          path={stageSwitchRoutes.deiForm.path}
          element={<StageActionDeiForm />}
        />
        <Route
          path={stageSwitchRoutes.roleSpecificSkillsAssessment.path}
          element={<RoleSpecificSkillsAssessment />}
        />
        <Route
          path={stageSwitchRoutes.skillsSelfAssessmentThankYou.path}
          element={<SkillsAssessmentThankYou />}
        />
        <Route
          path={stageSwitchRoutes.codingAssessmentIntro.path}
          element={<CodingIntro />}
        />
        <Route path="/" element={<RootRedirection />} />
      </Route>

      <Route
        path={appSwitchRoutes.companyNavigation.path}
        element={<CompanyNavigation />}
      />
      <Route
        path={appSwitchRoutes.onboarding.path}
        element={<TalentReviewInfo />}
      />
      <Route
        path={appSwitchRoutes.authentication.path}
        element={<Authentication />}
      />
      <Route
        path={appSwitchRoutes.externalService.path}
        element={<ExternalServiceLanding />}
      />
    </Routes>
  );
}

export default AppSwitch;
