import { stageSwitchRoutes } from 'constants/routes';
import {
  StageActionAvailabilityStatus,
  StageActionFragment,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StageActionCard from '../StageActionCard';

type TCodingActionCardProps = Extract<
  StageActionFragment,
  { __typename?: 'StageActionExternalAssessmentModel' }
>;

function StageActionCodingCard({
  availability,
  title,
  eaStageActionCandidate,
  invitationLink,
  allowedTotalTime,
  estimatedTotalTime,
}: TCodingActionCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isCardAvailable =
    availability.status === StageActionAvailabilityStatus.Available;

  const testUrl = stageSwitchRoutes.codingAssessmentIntro.getUrl(
    eaStageActionCandidate.stageCandidateId,
    eaStageActionCandidate.id
  );

  function handleCardClick(event: React.MouseEvent) {
    event.preventDefault();
    if (isCardAvailable) {
      navigate(testUrl, {
        state: {
          codingInvitationURL: invitationLink,
          totalTimeAllowanceSeconds: allowedTotalTime,
          totalTimeEstimateSeconds: estimatedTotalTime,
        },
      });
    }
  }

  const ariaLabel =
    StageActionAvailabilityStatus.Completed === availability.status
      ? t('codingAssessment.codingAssessmentCompleted')
      : t('codingAssessment.codingAssessment');

  return (
    <StageActionCard
      status={availability.status}
      title={title}
      icon="cog"
      onClick={handleCardClick}
      ariaLabel={ariaLabel}
      linkTo={''}
    />
  );
}

export default StageActionCodingCard;
